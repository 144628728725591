<template>
  <div>
    <div class="mb-3">
      <h1 :class="$style.pageTitle" style="display:inline-block;">会社一覧</h1>
      <router-link :to="{ name: 'companyCreate' }">
        <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">作成</a-button>
      </router-link>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header" style="padding-left: 10px !important;">
            <div :class="$style.filterHeader">フィルター</div>
            <a-form layout="inline">
              <a-form-item label="プラン">
                <a-select
                  mode="multiple"
                  placeholder="プラン"
                  :value="selectedPlans"
                  style="min-width: 200px"
                  @change="handleChange"
                >
                  <a-select-option v-for="item in filteredOptions" :key="item.value" :value="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="社名">
                <form-multiple-select
                  placeholder="社名"
                  :candidates="companyData"
                  style="min-width: 200px"
                  @changeSelectedItems="changeSelectedCompanyName"
                />
              </a-form-item>
              <a-form-item label="ID">
                <form-multiple-select
                  placeholder="ID"
                  :candidates="companyDataIds"
                  style="min-width: 200px"
                  @changeSelectedItems="changeSelectedCompanyId"
                />
              </a-form-item>
            </a-form>
            <hr style="margin: 20px 0 0 0">
          </div>
          <div class="card-body">
            <div v-if="companyLoading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!companyLoading">
              <a-list
                item-layout="horizontal"
                :data-source="filteredCompanyData"
                :pagination="true"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a slot="actions">
                    <a-button type="primary" shape="round" icon="pushpin" @click="showModalBelong(item.id)" :class="$style.filledBtn">所属する</a-button>
                    <router-link :to="{ name: 'companyUpdate', params: { id: item.id, company: item }}">
                      <a-button type="default" shape="round" icon="edit" style="margin-left: 10px;">編集</a-button>
                    </router-link>
                    <a-button type="danger" shape="round" icon="delete" style="margin-left: 10px;" @click="showModalDelete(item.id)">削除</a-button>
                  </a>
                  <a-list-item-meta
                    :description="item.comment"
                  >
                    <p slot="title" class="list-name">
                      <a-tag :color="plans[item.plan].color">{{ plans[item.plan].label }}</a-tag>
                      {{ item.id }}: {{ item.name }}
                    </p>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
              <a-modal
                :title="title"
                :visible="visibleDelete"
                :confirm-loading="confirmLoading"
                okText='削除'
                okType='danger'
                cancelText='キャンセル'
                @ok="handleOkDelete"
                @cancel="handleCancelDelete"
              />
              <a-modal
                :title="title"
                :visible="visibleBelong"
                :confirm-loading="confirmLoading"
                okText='所属する'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkBelong"
                @cancel="handleCancelBelong"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import { enumData } from '@/services/enum'
import FormMultipleSelect from '../../../components/FormComponents/MultipleSelect'

const OPTIONS = enumData.companyPlan

export default {
  components: {
    FormMultipleSelect,
  },
  data() {
    return {
      companyData: [],
      companyLoading: true,
      visibleBelong: false,
      visibleDelete: false,
      title: '',
      confirmLoading: false,
      deleteTargetIndex: null,
      deleteTargetCompany: Object,
      belongTargetCompany: Object,
      dataBelong: {
        company_id: null,
      },
      plans: [],
      selectedPlans: [],
      selectedCompanies: [],
      selectedCompanieIds: [],
    }
  },
  computed: {
    filteredOptions() {
      return OPTIONS.filter(o => !this.selectedPlans.includes(o))
    },
    filteredCompanyData() {
      var restCompanyData = this.companyData
      if (this.selectedPlans.length) restCompanyData = restCompanyData.filter(c => this.selectedPlansValue.includes(c.plan))
      if (this.selectedCompanies.length) restCompanyData = restCompanyData.filter(c => this.selectedCompanies.includes(c.id))
      if (this.selectedCompanieIds.length) restCompanyData = restCompanyData.filter(c => this.selectedCompanieIds.includes(c.id))
      return restCompanyData
    },
    selectedPlansValue() {
      return this.selectedPlans.map(v => enumData.companyPlan.find(target => target.label === v).value)
    },
    companyDataIds() {
      return this.companyData.map(c => { return { id: c.id, name: c.id } })
    },
  },
  created() {
    enumData.companyPlan.forEach(p => { this.plans[p.value] = { label: p.label, color: p.tag } })
    const companyList = Vue.prototype.$api.send('get', 'companies_simple')
    companyList.then(response => {
      this.companyLoading = false
      this.companyData = response
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社の取得に失敗しました。',
        })
        this.companyLoading = false
      })
    console.log('message')
  },
  methods: {
    showModalBelong(companyId) {
      console.log('show Modal')
      this.belongTargetCompany = this.companyData.find(c => c.id === companyId)
      this.title = '所属を ' + this.belongTargetCompany.name + ' に変更しますか？'
      this.visibleBelong = true
    },
    handleOkBelong() {
      const _this = this
      console.log('handle ok')
      this.confirmLoading = true
      this.dataBelong['company_id'] = this.belongTargetCompany.id
      const result = Vue.prototype.$api.send('put', 'user/change_belong_company', this.dataBelong)
      result.then(response => {
        Vue.prototype.$auth.userInfo()
        _this.$notification['success']({
          message: '所属を ' + this.belongTargetCompany.name + ' に変更しました。',
        })
        // _this.$message.destroy()
        // _this.$message.info({
        //   content: this.belongTargetCompany.id + ': 所属会社を' + this.belongTargetCompany.name + 'にしています。',
        //   duration: 0,
        // })
        this.confirmLoading = false
        this.visibleBelong = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 所属の変更に失敗しました。',
          })
          this.confirmLoading = false
          this.visibleBelong = false
        })
    },
    handleCancelBelong() {
      console.log('handle cancel')
      this.visibleBelong = false
    },

    showModalDelete(companyId) {
      console.log('show Modal')
      this.deleteTargetCompany = this.companyData.find(c => c.id === companyId)
      this.deleteTargetIndex = this.companyData.findIndex(c => c.id === companyId)
      this.title = '本当に ' + this.deleteTargetCompany.name + ' を削除しますか？'
      this.visibleDelete = true
    },
    handleOkDelete() {
      const _this = this
      console.log('handle ok')
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('delete', 'companies/' + this.deleteTargetCompany.id)
      result.then(response => {
        _this.$notification['success']({
          message: this.deleteTargetCompany.name + ' を削除しました。',
        })
        this.companyData.splice(this.deleteTargetIndex, 1)
        this.deleteTargetCompany = Object
        this.confirmLoading = false
        this.visibleDelete = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社の削除に失敗しました。',
          })
          this.confirmLoading = false
          this.visibleDelete = false
          this.deleteTargetCompany = Object
        })
    },
    handleCancelDelete() {
      console.log('handle cancel')
      this.visibleDelete = false
    },

    handleChange(selectedPlans) { this.selectedPlans = selectedPlans },
    changeSelectedCompanyName(vals) { this.selectedCompanies = vals },
    changeSelectedCompanyId(vals) { this.selectedCompanieIds = vals },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.filterHeader {
  font: normal normal bold 14px/33px $v2-font-family;
  letter-spacing: 0px;
}
.list-name {
  font-size: 1.5rem;
}
</style>
